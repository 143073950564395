import React, { FC, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  SwipeableDrawer,
  TextField,
  Typography,
} from '@mui/material';
import { BackButton, DrawerContent, PageTitle } from 'shared/ui';
import { submitted } from 'pages/order/payment/model';
import { routes } from 'shared/routes';
import { intl } from 'shared/lib';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { orderModel } from 'entities/order';

export const PaymentPage: FC = () => {
  const handleSubmit = () => {
    submitted();
  };

  const handleBackButtonClick = () => {
    routes.order.info.open({});
  };

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        pb: 6,
      }}
    >
      <BackButton onClick={handleBackButtonClick} />
      <Container maxWidth="sm">
        <PageTitle header={'Проверьте данные'} subtitle={'И выберите способ оплаты'} backLink={routes.order.info} />
        <Stack spacing={3}>
          <Box
            sx={{
              mx: '-16px',
            }}
          >
            <OrderInfo />
          </Box>
        </Stack>
      </Container>

      <Box
        sx={{
          height: '80px',
        }}
      />
      <Box
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          width: '100%',
          pb: 3,
        }}
      >
        <Container maxWidth="sm">
          <Button size={'large'} fullWidth variant={'contained'} onClick={handleSubmit}>
            Записаться
          </Button>
        </Container>
      </Box>
    </Box>
  );
};

const OrderInfo: FC = () => {
  const order = orderModel.useOrder();

  const { formatDate, formatTime, formatPriceMethod } = useMemo(() => {
    const slot = order.slot;

    let formatDate = '';
    let formatTime = '';
    let formatPriceMethod = '';

    if (slot?.startTimeISO) {
      const date = new Date(slot.startTimeISO);

      formatDate = intl.dateFormat(date, {
        dateStyle: 'medium',
        timeZone: slot.timezone,
      });

      formatTime = intl.dateFormat(date, {
        timeStyle: 'short',
        timeZone: slot.timezone,
      });
    }

    if (order.payment) {
      formatPriceMethod = `${order.payment.token}(${order.payment.network})`;
    }

    return {
      formatDate,
      formatTime,
      formatPriceMethod,
    };
  }, [order]);

  const [isDrawerPaymentOpen, setIsDrawerPaymentOpen] = useState<boolean>(false);
  const togglePaymentDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    setIsDrawerPaymentOpen(open);
  };

  const [isDrawerCouponOpen, setIsDrawerCouponOpen] = useState<boolean>(false);
  const toggleCouponDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    setIsDrawerCouponOpen(open);
  };

  const [coupon, setCoupon] = useState<string>(order.coupon || '');
  const handleCouponChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setCoupon(value);
  };

  const handleCouponSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    orderModel.couponChanged(coupon);
    setIsDrawerCouponOpen(false);
  };

  const { tokens, methods: paymentMethods } = orderModel.usePaymentMethods();

  const [token, setToken] = useState(order.payment.token);
  const [network, setNetwork] = useState<string>(order.payment.network);

  const handleTokenChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newToken = e.target.value;
    const newNetwork = paymentMethods[newToken].some((option) => option.value === network)
      ? network
      : paymentMethods[newToken][0].value;
    setToken(newToken);
    setNetwork(newNetwork);
  };

  const handleNetworkChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newNetwork = e.target.value;
    setNetwork(newNetwork);
  };

  const handlePaymentSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    orderModel.paymentChanged({
      token,
      network,
    });
    setIsDrawerPaymentOpen(false);
  };

  return (
    <>
      <List>
        <ListItem>
          <ListItemText
            primary={
              <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="text.secondary">
                Услуга
              </Typography>
            }
            secondary={<Typography color="text.primary">{order.service?.name}</Typography>}
          />
        </ListItem>
        <Divider variant={'fullWidth'} component="li" />

        <ListItem>
          <ListItemText
            primary={
              <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="text.secondary">
                Дата
              </Typography>
            }
            secondary={<Typography color="text.primary">{formatDate}</Typography>}
          />
        </ListItem>
        <Divider variant={'fullWidth'} component="li" />

        <ListItem>
          <ListItemText
            primary={
              <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="text.secondary">
                Время
              </Typography>
            }
            secondary={<Typography color="text.primary">{formatTime}</Typography>}
          />
        </ListItem>
        <Divider variant={'fullWidth'} component="li" />

        <ListItem>
          <ListItemText
            primary={
              <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="text.secondary">
                Сумма
              </Typography>
            }
            secondary={<Typography color="text.primary">{order.price}$</Typography>}
          />
        </ListItem>
        <Divider variant={'fullWidth'} component="li" />
        <ListItemButton onClick={toggleCouponDrawer(true)}>
          <ListItemText primary={'Промокод'} secondary={<Typography color="text.primary">{order.coupon}</Typography>} />
          <ArrowForwardIosRoundedIcon fontSize={'small'} color={'disabled'} />
        </ListItemButton>
        <Divider variant={'fullWidth'} component="li" />
        <ListItemButton onClick={togglePaymentDrawer(true)}>
          <ListItemText
            primary={
              <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="text.secondary">
                Способ оплаты
              </Typography>
            }
            secondary={<Typography color="text.primary">{formatPriceMethod}</Typography>}
          />
          <ArrowForwardIosRoundedIcon fontSize={'small'} color={'disabled'} />
        </ListItemButton>
      </List>
      <SwipeableDrawer
        anchor={'bottom'}
        open={isDrawerPaymentOpen}
        onClose={togglePaymentDrawer(false)}
        onOpen={togglePaymentDrawer(true)}
        elevation={1}
      >
        <form autoComplete="off" noValidate onSubmit={handlePaymentSubmit}>
          <DrawerContent
            onClose={togglePaymentDrawer(false)}
            action={
              <Button fullWidth variant={'contained'} size={'large'} type={'submit'}>
                Сохранить
              </Button>
            }
          >
            <Typography variant={'h3'} mb={3}>
              Изменить способ оплаты
            </Typography>
            <Box>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Токен"
                    name="token"
                    onChange={handleTokenChange}
                    select
                    SelectProps={{ native: true }}
                    value={token}
                    InputLabelProps={{ shrink: true }}
                  >
                    {tokens.map((token) => (
                      <option key={token} value={token}>
                        {token}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Сеть"
                    name="network"
                    onChange={handleNetworkChange}
                    select
                    SelectProps={{ native: true }}
                    value={network}
                    InputLabelProps={{ shrink: true }}
                  >
                    {paymentMethods[token].map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
            </Box>
          </DrawerContent>
        </form>
      </SwipeableDrawer>

      <SwipeableDrawer
        anchor={'bottom'}
        open={isDrawerCouponOpen}
        onClose={toggleCouponDrawer(false)}
        onOpen={toggleCouponDrawer(true)}
        elevation={1}
      >
        <form autoComplete="off" noValidate onSubmit={handleCouponSubmit}>
          <DrawerContent
            onClose={toggleCouponDrawer(false)}
            action={
              <Button fullWidth variant={'contained'} size={'large'} type={'submit'}>
                Применить
              </Button>
            }
          >
            <Typography variant={'h3'} mb={3}>
              Введите промокод
            </Typography>
            <Box>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField fullWidth label="Промокод" name="coupon" onChange={handleCouponChange} value={coupon} />
                </Grid>
              </Grid>
            </Box>
          </DrawerContent>
        </form>
      </SwipeableDrawer>
    </>
  );
};
