import { common } from '@mui/material/colors';
import { alpha } from '@mui/material/styles';
import { error, indigo, info, neutral, success, warning } from './colors';
import { PaletteMode } from '@mui/material';

export function createPalette(mode: PaletteMode) {
  if (mode === 'light') {
    return {
      action: {
        active: neutral[500],
        disabled: alpha(neutral[900], 0.38),
        disabledBackground: alpha(neutral[900], 0.12),
        focus: alpha(neutral[900], 0.16),
        hover: alpha(neutral[900], 0.04),
        selected: alpha(neutral[900], 0.12),
      },
      background: {
        default: common.white,
        paper: common.white,
      },
      divider: '#F2F4F7',
      error,
      info,
      mode: 'light',
      neutral,
      primary: indigo,
      success,
      text: {
        primary: neutral[900],
        secondary: neutral[500],
        disabled: alpha(neutral[900], 0.38),
      },
      warning,
    };
  } else {
    return {
      action: {
        active: '#fff',
        disabled: alpha('#ffffff', 0.38),
        disabledBackground: alpha('#ffffff', 0.12),
        focus: alpha('#ffffff', 0.16),
        hover: alpha('#ffffff', 0.04),
        selected: alpha('#ffffff', 0.12),
      },
      background: {
        default: '#0e141b',
        paper: '#0e141b',
      },
      divider: alpha('#ffffff', 0.08),
      error,
      info,
      mode: 'dark',
      neutral,
      primary: indigo,
      success,
      warning,
      text: {
        primary: '#fff',
        secondary: alpha('#ffffff', 0.7),
        disabled: alpha('#ffffff', 0.5),
      },
    };
  }
}
