import { Box, Container, Stack, Typography, Button, Grid } from '@mui/material';
import { Link as RouterLink } from 'atomic-router-react';
import React, { useEffect } from 'react';
import { useStore } from 'effector-react';
import { PageTitle, BackButton } from 'shared/ui';
import { BookingCard, bookingModel } from 'entities/booking';
import { routes } from 'shared/routes';

export const BookingsListPage = () => {
  const { loading: isLoading, data: bookings } = useStore(bookingModel.$getBookingListStatus);

  useEffect(() => {
    bookingModel.effects.getBookingListFx();
  }, []);

  const handleBackButtonClick = () => {
    routes.home.open();
  };

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        pb: 6,
      }}
    >
      <BackButton onClick={handleBackButtonClick} />
      <Container maxWidth="sm">
        <PageTitle header={'История'} subtitle={'Прошедшие консультации'} backLink={routes.home} />

        <Grid container spacing={3}>
          {isLoading &&
            Array.from(new Array(5)).map((item, index) => (
              <Grid item key={index} xs={12}>
                <BookingCard loading={true} key={index} />
              </Grid>
            ))}

          {!isLoading &&
            bookings.map((booking, index) => (
              <Grid item key={booking.id} xs={12}>
                <BookingCard data={booking} />
              </Grid>
            ))}
        </Grid>

        {!isLoading && bookings.length === 0 && (
          <Box my={3}>
            <Stack spacing={2} alignItems={'center'}>
              <Typography variant={'subtitle1'}>Пока что тут пусто😢</Typography>
              <Button size={'large'} variant={'contained'} component={RouterLink} to={routes.order.services}>
                Записаться
              </Button>
            </Stack>
          </Box>
        )}
      </Container>
    </Box>
  );
};
