import React, { useEffect } from 'react';
import { changedLoading, checkFx, loginFx } from 'entities/session';
import { hooks, intl } from 'shared/lib';

export const withAuth = (component: () => React.ReactNode) => () => {
  const { tg, user: telegramUser } = hooks.useTelegram();

  useEffect(() => {
    const timezone = intl.getTimeZone();

    // loginFx({
    //   firstName: telegramUser?.first_name ? telegramUser?.first_name : 'Кирилл',
    //   lastName: telegramUser?.last_name ? telegramUser?.last_name : 'Глад',
    //   telegramChatId: telegramUser?.id ? `${telegramUser?.id}` : '11111112',
    //   timezone: timezone,
    //   initData: tg.initData ? tg.initData : 'true',
    // });

    const login = async () => {
      try {
        await checkFx();
        changedLoading(false);
      } catch {
        const response = await loginFx({
          email: 'blacesmot@gmail.com',
          password: '12345678',
        });
        //console.log(response);
      }
    };

    login();
  }, [tg, telegramUser]);

  return <>{component()}</>;
};
