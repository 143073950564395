import { createEvent, createStore, sample, split } from 'effector';
import { orderModel } from 'entities/order';
import { controls, routes } from 'shared/routes';
import { redirect } from 'atomic-router';

const currentRoute = routes.order.info;

export const $pageParams = currentRoute.$params;
export const $isUserInfoEdit = $pageParams.map((params) => params?.tab === 'user-edit');
export const openUserInfoEdit = createEvent();
export const closeUserInfoEdit = createEvent();

redirect({
  clock: openUserInfoEdit,
  params: { tab: 'user-edit' },
  route: currentRoute,
});

sample({
  clock: closeUserInfoEdit,
  target: controls.back,
});

type formType = {
  comment?: string;
};

export const setField = createEvent<{ [key: string]: string }>();
export const redirectedPrevStep = createEvent();
export const submitted = createEvent();
export const pageOpened = createEvent();
export const pageClosed = createEvent();

export const formInitialState: formType = {};

export const $form = createStore<formType>(formInitialState)
  .on(setField, (s, { key, value }) => ({
    ...s,
    [key]: value,
  }))
  .reset(pageClosed);

split({
  source: orderModel.$order,
  clock: currentRoute.opened,
  match: {
    open: (order) => !!order.slot,
    redirect: (order) => !order.slot,
  },
  cases: {
    open: pageOpened,
    redirect: redirectedPrevStep,
  },
});

sample({
  clock: pageOpened,
  source: orderModel.$order,
  fn: (order) => {
    return order?.info || {};
  },
  target: $form,
});

sample({
  clock: submitted,
  source: $form,
  target: orderModel.infoChanged,
});

sample({
  clock: currentRoute.closed,
  target: pageClosed,
});
