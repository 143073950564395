import { AxiosResponse } from 'axios';
import type { User } from 'shared/api/cryptomedic/models';
import { $api } from 'shared/api/cryptomedic/base';

export const login = async (data: any): Promise<AxiosResponse<User>> => {
  return $api.post('auth/login', data);
};

export const check = async (): Promise<AxiosResponse<User>> => {
  return $api.get('auth/check');
};

export const refreshToken = async (): Promise<AxiosResponse<User>> => {
  return $api.get('auth/refresh');
};

export const me = async (): Promise<AxiosResponse<User>> => {
  return $api.get('telegram-client-webapp/me');
};

export const update = async (data: any): Promise<AxiosResponse<User>> => {
  return $api.patch('telegram-client-webapp/update', data);
};
