import React, { FC, PropsWithChildren } from 'react';
import { Box, Card, CardActionArea, CardContent, Skeleton, Stack, Typography } from '@mui/material';
import { Service } from 'shared/api';
import { duration, currency } from 'shared/lib';

type ServiceCardProps = PropsWithChildren<{
  data?: Service;
  loading?: boolean;
  onClick?: (data: Service) => void;
  selected?: boolean;
}>;

export const ServiceCard: FC<ServiceCardProps> = ({ data, onClick, selected, ...cardProps }) => {
  if (cardProps.loading) {
    return (
      <Card>
        <CardContent
          sx={{
            py: 3,
            px: 3,
          }}
        >
          <Stack alignItems="center" direction="row" justifyContent="space-between" spacing={3}>
            <Stack
              sx={{
                width: '40%',
              }}
            >
              <Skeleton variant="text" sx={{ fontSize: '16px' }} />
              <Skeleton variant="text" width={'60%'} sx={{ fontSize: '14px' }} />
            </Stack>
            <Skeleton variant="text" width={'30%'} sx={{ fontSize: '16px' }} />
          </Stack>
        </CardContent>
      </Card>
    );
  }

  if (!data) {
    return null;
  }

  const handleClick = (data: Service) => {
    if (onClick) {
      onClick(data);
    }
  };

  const serviceDuration = duration.convertMinutesToHoursAndMinutes(data.duration);
  const price = currency.formatPrice(data.price);

  return (
    <Card>
      <CardActionArea onClick={() => handleClick(data)}>
        <CardContent
          sx={{
            py: 3,
            px: 3,
            position: 'relative',
          }}
        >
          {selected && (
            <Box
              sx={{
                position: 'absolute',
                width: '5px',
                height: '100%',
                top: 0,
                left: 0,
                backgroundColor: 'primary.main',
              }}
            />
          )}

          <Stack alignItems="center" direction="row" justifyContent="space-between" spacing={3}>
            <Stack>
              <Typography variant="h5" component={'div'}>
                {data.name}
              </Typography>
              <Typography
                color={'text.secondary'}
                mt={0.5}
                sx={{
                  fontSize: '14px',
                }}
              >
                Сеанс: {serviceDuration.formatString}
              </Typography>
            </Stack>
            <Typography color={'primary'} variant={'h6'}>
              {price}
            </Typography>
          </Stack>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
