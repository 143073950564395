import React, { FC } from 'react';
import { Box, Button as MuiButton, ButtonProps as MuiButtonProps, CircularProgress } from '@mui/material';

export interface ButtonProps extends MuiButtonProps {
  loading?: boolean;
}

export const Button: FC<ButtonProps> = ({ loading, children, ...props }) => {
  return (
    <Box
      sx={{
        position: 'relative',
      }}
    >
      <MuiButton {...props}>{children}</MuiButton>
      {loading && (
        <CircularProgress
          size={24}
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: '-12px',
            marginLeft: '-12px',
          }}
        />
      )}
    </Box>
  );
};
