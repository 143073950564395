import { AxiosResponse } from 'axios';
import { Booking } from './models';
import { $api } from './base';

export const getBookingsList = async (): Promise<AxiosResponse<Booking[]>> => {
  return $api.get('telegram-client-webapp/bookings');
};

export const getBookingById = async (bookingId: string): Promise<AxiosResponse<Booking>> => {
  return $api.get(`telegram-client-webapp/bookings/${bookingId}`);
};
