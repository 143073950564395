import { createEvent, sample } from 'effector';
import { orderModel } from 'entities/order';

// export const backLinkClicked = createEvent();
// redirect({
//   clock: backLinkClicked,
//   route: routes.order.info,
// });

export const submitted = createEvent();

sample({
  clock: submitted,
  source: {},
  target: orderModel.submittedOrder,
});
