import { useLocation, Navigate } from 'react-router-dom';
import { ReactNode } from 'react';
import { useAuth } from 'entities/session';

export const RequireAuth = ({ children }: { children: ReactNode }) => {
  const location = useLocation();
  const { user } = useAuth();

  if (!user) {
    return <Navigate to="/" replace state={{ from: location }} />;
  }

  return <>{children}</>;
};
