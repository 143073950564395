export enum BookingStatusEnum {
  APPROVED = 'approved',
  PAYMENT_APPROVAL = 'payment_approval',
  PAYMENT_PENDING = 'payment_pending',
  COMPLETED = 'completed',
  CANCELLED = 'cancelled',
}

export type BookingStatusType = `${BookingStatusEnum}`;

export type Booking = {
  id: string;
  bookingCode: string;
  startDateTime: string;
  timezone: string;
  status: BookingStatusType;
  duration: number;
  comment: string;
};

export type User = {
  id: string;
  firstName: string;
  lastName: string;
  birthday: string;
  sex: string;
  email: string;
  timezone: string;
  telegramChatId: string;
};

export type Slot = {
  available: boolean;
  date: string;
  startTime: number;
  endTime: number;
  timezone: string;
  timezoneOffset: number;
  startTimeISO: string;
  endTimeISO: string;
  minute: number;
};

export type slotGroups = {
  [key: string]: Slot[];
};

export type Service = {
  id: string;
  name: string;
  description: string;
  price: number;
  duration: number;
};
