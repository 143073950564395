import './index.css';
import React, { FC, useEffect } from 'react';
import { Box, CircularProgress, Container, Link, Stack, Typography } from '@mui/material';
import { useTelegram } from 'shared/lib/hooks/use-telegram';
import { withProviders } from 'app/providers';
import { AuthGuard } from 'entities/session';
import { PageTitle } from 'shared/ui';
import { Pages } from 'pages';
import { createBrowserHistory } from 'history';
import { router } from 'app/providers/with-router';

const Content: FC = () => {
  const history = createBrowserHistory();
  router.setHistory(history);

  return <Pages />;
};

function App() {
  const { tg } = useTelegram();

  useEffect(() => {
    tg.ready();
    tg.expand();
    tg.enableClosingConfirmation();
  }, [tg]);

  return (
    <div className="app">
      <AuthGuard pending={<Spinner />} fallback={<Failed />}>
        <Content />
      </AuthGuard>
    </div>
  );
}

const Spinner: FC = () => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
    }}
  >
    <CircularProgress color={'primary'} size={'60px'} />
  </Box>
);

const Failed: FC = () => (
  <Box
    component="main"
    sx={{
      flexGrow: 1,
      py: 6,
    }}
  >
    <Container maxWidth="sm">
      <PageTitle header={'Пользователь не найден'} />
      <Stack spacing={2}>
        <Typography textAlign={'center'}>Попробуйте перезагрузить приложение или попробовать позже</Typography>
        <Typography textAlign={'center'}>
          Или свяжитесь с нами <Link href={'mailto:info@cryptomedic.ru'}>info@cryptomedic.ru</Link>
        </Typography>
      </Stack>
    </Container>
  </Box>
);

export default withProviders(App);
