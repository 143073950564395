import { Box, Button, Container, Stack, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { useTelegram } from 'shared/lib/hooks';
import { Link } from 'atomic-router-react';
import { routes } from 'shared/routes';

export const HomePage = () => {
  const { user } = useTelegram();

  const pageTitle = useMemo(() => {
    const username = user?.username ?? '';
    return `Привет ${username}!`;
  }, [user]);

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 6,
      }}
    >
      <Container maxWidth="sm">
        <Box
          mb={3}
          sx={{
            position: 'relative',
          }}
        >
          <Stack spacing={1}>
            <Typography textAlign="center" variant="h1">
              {pageTitle}
            </Typography>

            <Typography
              textAlign="center"
              variant="body2"
              sx={{
                lineHeight: '1.3',
              }}
            >
              Рады вас видеть
            </Typography>
          </Stack>
        </Box>

        <Stack spacing={2}>
          <Button
            sx={{
              height: 56,
            }}
            fullWidth
            size="large"
            variant="contained"
            component={Link}
            to={routes.order.services}
          >
            Записаться
          </Button>
          <Button
            sx={{
              height: 56,
            }}
            fullWidth
            size="large"
            variant={'outlined'}
            component={Link}
            to={routes.booking.list}
          >
            История
          </Button>
          <Button
            sx={{
              height: 56,
            }}
            fullWidth
            size="large"
            variant={'outlined'}
            component={Link}
            to={routes.settings}
          >
            Настройки
          </Button>
        </Stack>
      </Container>
    </Box>
  );
};
