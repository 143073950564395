import React, { FC, useCallback, useState } from 'react';
import { Button, DrawerContent, PageTitle, BackButton } from 'shared/ui';
import {
  Avatar,
  Box,
  Card,
  CardActionArea,
  CardContent,
  Container,
  Grid,
  Stack,
  SvgIcon,
  SwipeableDrawer,
  TextField,
  Typography,
} from '@mui/material';
import { routes } from 'shared/routes';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import {
  setField,
  $form,
  submitted,
  closeUserInfoEdit,
  openUserInfoEdit,
  $isUserInfoEdit,
} from 'pages/order/info/model';
import { useStore } from 'effector-react';
import { updateUserFx, useAuth } from 'entities/session';

export const InfoPage: FC = () => {
  const updateUserLoading = useStore(updateUserFx.pending);
  const isDrawerOpened = useStore($isUserInfoEdit);
  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    open ? openUserInfoEdit() : closeUserInfoEdit();
  };

  const handleChange = setField.prepend((e: React.ChangeEvent<HTMLInputElement>) => ({
    key: e.target.name,
    value: e.target.value,
  }));

  const handleSubmit = () => {
    submitted();
  };

  const form = useStore($form);
  const { user } = useAuth();
  const [values, setValues] = useState({
    firstName: user?.firstName ?? '',
    lastName: user?.lastName ?? '',
    email: user?.email ?? '',
    birthday: user?.birthday ?? '',
    sex: user?.sex ?? '',
  });

  const handleChangeForm = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setValues((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  }, []);
  const handleSubmitForm = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      await updateUserFx(values);
      closeUserInfoEdit();
    } catch (e: any) {
      console.log(e);
    }
  };

  const handleBackButtonClick = () => {
    routes.order.slots.open({});
  };

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        pb: 6,
      }}
    >
      <BackButton onClick={handleBackButtonClick} />
      <Container maxWidth="sm">
        <PageTitle
          header={'Заполните данные'}
          subtitle={'Проверьте данные и опишите проблему'}
          backLink={routes.order.slots}
        />
        <Stack spacing={3}>
          <Card>
            <CardActionArea onClick={toggleDrawer(true)}>
              <CardContent
                sx={{
                  py: 2,
                  px: 3,
                }}
              >
                <Stack alignItems="center" direction="row" justifyContent="space-between" spacing={3}>
                  <Stack>
                    <Typography
                      variant="h6"
                      sx={{
                        fontSize: '16px',
                      }}
                    >
                      {user?.firstName} {user?.lastName}
                    </Typography>
                  </Stack>
                  <Avatar
                    sx={{
                      backgroundColor: 'primary.main',
                      height: 36,
                      width: 36,
                      color: 'primary.contrastText',
                    }}
                  >
                    <SvgIcon sx={{ fontSize: '20px' }}>
                      <SettingsOutlinedIcon />
                    </SvgIcon>
                  </Avatar>
                </Stack>
              </CardContent>
            </CardActionArea>
          </Card>
          <TextField
            fullWidth
            label="Опишите проблему (необязательно)"
            name="comment"
            multiline
            rows={4}
            value={form['comment']}
            onChange={handleChange}
          />
        </Stack>
      </Container>
      <Box
        sx={{
          height: '80px',
        }}
      />
      <Box
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          width: '100%',
          pb: 3,
        }}
      >
        <Container maxWidth="sm">
          <Button size={'large'} fullWidth variant={'contained'} onClick={handleSubmit}>
            Дальше
          </Button>
        </Container>
      </Box>
      <SwipeableDrawer
        anchor={'bottom'}
        open={isDrawerOpened}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        elevation={1}
      >
        <form autoComplete="off" noValidate onSubmit={handleSubmitForm}>
          <DrawerContent
            sx={{
              height: '90vh',
            }}
            onClose={toggleDrawer(false)}
            action={
              <Button
                fullWidth
                variant={'contained'}
                size={'large'}
                type={'submit'}
                loading={updateUserLoading}
                disabled={updateUserLoading}
              >
                Сохранить
              </Button>
            }
          >
            <Typography variant={'h3'} mb={3}>
              Изменить данные
            </Typography>
            <Box>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Имя"
                    name="firstName"
                    onChange={handleChangeForm}
                    value={values.firstName}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Фамилия"
                    name="lastName"
                    onChange={handleChangeForm}
                    value={values.lastName}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField fullWidth label="Email" name="email" onChange={handleChangeForm} value={values.email} />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Дата рождения"
                    name="birthday"
                    onChange={handleChangeForm}
                    type="date"
                    value={values.birthday}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Пол"
                    name="sex"
                    onChange={handleChangeForm}
                    select
                    SelectProps={{ native: true }}
                    value={values.sex}
                    InputLabelProps={{ shrink: true }}
                  >
                    <option value="">Не выбрано</option>
                    <option value="male">Мужской</option>
                    <option value="female">Женский</option>
                    <option value="other">Другой</option>
                  </TextField>
                </Grid>
              </Grid>
            </Box>
          </DrawerContent>
        </form>
      </SwipeableDrawer>
    </Box>
  );
};
