type dateFormatOptions = {
  locale?: string;
  dateStyle?: 'full' | 'long' | 'medium' | 'short';
  timeStyle?: 'full' | 'long' | 'medium' | 'short';
  timeZone?: string;
};

export function dateFormat(date: Date, options: dateFormatOptions = {}): string {
  const { locale, ...formatOptions } = options;
  try {
    return new Intl.DateTimeFormat(locale, formatOptions).format(date);
  } catch {
    return '';
  }
}

export function getTimeZone(defaultTimeZone: string = 'Europe/Moscow') {
  try {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  } catch {
    return defaultTimeZone;
  }
}
