import React, { FC, useMemo } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { BookingStatusEnum, BookingStatusType } from 'shared/api';

export type BookingStatusProps = {
  status: BookingStatusType;
};

const statuses = {
  [BookingStatusEnum.APPROVED]: {
    label: 'Принято',
    color: 'info.main',
  },
  [BookingStatusEnum.CANCELLED]: {
    label: 'Отменено',
    color: 'error.main',
  },
  [BookingStatusEnum.COMPLETED]: {
    label: 'Завершено',
    color: 'success.main',
  },
  [BookingStatusEnum.PAYMENT_APPROVAL]: {
    label: 'Подтверждение оплаты',
    color: 'warning.main',
  },
  [BookingStatusEnum.PAYMENT_PENDING]: {
    label: 'Ожидание оплаты',
    color: 'warning.main',
  },
};

export const BookingStatus: FC<BookingStatusProps> = ({ status }) => {
  const { color, label } = useMemo(() => {
    let { color, label } = statuses[status];
    return {
      color,
      label,
    };
  }, [status]);

  return (
    <Stack spacing={1} direction={'row'} alignItems={'center'}>
      <Box
        sx={{
          backgroundColor: color,
          height: 10,
          width: 10,
          borderRadius: '100%',
        }}
      />
      <Typography component={'span'} color={color} variant={'body2'}>
        {label}
      </Typography>
    </Stack>
  );
};
