import { combine, createEffect, createEvent, createStore, sample, split } from 'effector';
import { cryptomedicApi, slotGroups, Slot } from 'shared/api';
import { AxiosError, AxiosResponse } from 'axios';
import { controls, routes } from 'shared/routes';
import { orderModel } from 'entities/order';
import { redirect } from 'atomic-router';

const currentRoute = routes.order.slots;

export const $pageParams = currentRoute.$params;
export const $isTimeZoneEdit = $pageParams.map((params) => params?.tab === 'timezone-edit');
export const openTimeZoneEdit = createEvent();
export const closeTimeZoneEdit = createEvent();

redirect({
  clock: openTimeZoneEdit,
  params: { tab: 'timezone-edit' },
  route: currentRoute,
});

sample({
  clock: closeTimeZoneEdit,
  target: controls.back,
});

export const slotInitialState: slotGroups = {};
export const $slotGroups = createStore<slotGroups>(slotInitialState);
export const $days = $slotGroups.map((slotGroups) => Object.keys(slotGroups));
export const $currentDay = createStore<string | null>(null);
export const $slots = createStore<Slot[]>([]);
export const $selectedSlot = createStore<Slot | null>(null);

export const pageOpened = createEvent();
export const pageClosed = createEvent();
export const dateChanged = createEvent<string | null>();
export const selectedSlotChanged = createEvent<Slot | null>();
export const redirectedPrevStep = createEvent();
export const submitted = createEvent<Slot | null>();

$currentDay.on(dateChanged, (state, day) => day).reset(pageClosed);
$selectedSlot.on(selectedSlotChanged, (state, slot) => slot).reset(pageClosed);

export const getSlotGroupsFx = createEffect<typeof cryptomedicApi.calendar.getSlots, AxiosError>(
  cryptomedicApi.calendar.getSlots,
);

$slotGroups.on(getSlotGroupsFx.doneData, (_, payload) => payload.data);

export const $slotGroupsError = createStore<AxiosResponse | null>(null)
  .on(getSlotGroupsFx.failData, (state, error) => {
    return error.response;
  })
  .reset(getSlotGroupsFx);

export const $slotGroupsStatus = combine({
  loading: getSlotGroupsFx.pending,
  error: $slotGroupsError,
  data: $slotGroups,
});

split({
  source: orderModel.$order,
  clock: currentRoute.opened,
  match: {
    open: (order) => !!order.service,
    redirect: (order) => !order.service,
  },
  cases: {
    open: pageOpened,
    redirect: redirectedPrevStep,
  },
});

sample({
  clock: pageOpened,
  source: orderModel.$order,
  fn: (order) => {
    return order?.slot?.date || null;
  },
  target: dateChanged,
});

sample({
  clock: pageOpened,
  source: orderModel.$order,
  fn: (order) => {
    return order?.slot || null;
  },
  target: selectedSlotChanged,
});

sample({
  clock: currentRoute.opened,
  source: orderModel.$order,
  fn: (order) => {
    return {
      serviceId: order?.service?.id,
    };
  },
  target: getSlotGroupsFx,
});

sample({
  clock: $slotGroups,
  source: $selectedSlot,
  filter: (source, slotGroups): source is Slot => {
    return (
      !!source &&
      !Object.keys(slotGroups).some((day) => {
        return slotGroups[day].some((slot) => {
          return slot.startTimeISO === source?.startTimeISO;
        });
      })
    );
  },
  fn: () => {
    return null;
  },
  target: selectedSlotChanged,
});

sample({
  clock: $days,
  source: { $slotGroups, $currentDay },
  fn: ({ $slotGroups, $currentDay }, days) => {
    if ($currentDay && days.indexOf($currentDay) !== -1) {
      return $currentDay;
    } else {
      const firstDayWithSlots = days.find((day) => $slotGroups[day].length > 0);
      return firstDayWithSlots || days[0];
    }
  },
  target: dateChanged,
});

sample({
  clock: [$slotGroups, $currentDay],
  source: { $slotGroups, $currentDay },
  fn: ({ $slotGroups, $currentDay }) => {
    if ($currentDay && $slotGroups[$currentDay]) {
      return $slotGroups[$currentDay];
    } else {
      return [];
    }
  },
  target: $slots,
});

sample({
  source: submitted,
  filter: (source): source is Slot => !!source,
  target: orderModel.slotChanged,
});

sample({
  clock: currentRoute.closed,
  target: pageClosed,
});
