import { combine, createEffect, createStore } from 'effector';
import { cryptomedicApi } from 'shared/api';
import type { Booking } from 'shared/api';
import { AxiosError, AxiosResponse } from 'axios';

export const tasksInitialState: Booking[] = [];
export const $bookings = createStore<Booking[]>(tasksInitialState);
export const $booking = createStore<Booking | null>(null);

const getBookingListFx = createEffect<typeof cryptomedicApi.booking.getBookingsList, AxiosError>(() => {
  return cryptomedicApi.booking.getBookingsList();
});

const getBookingByIdFx = createEffect<typeof cryptomedicApi.booking.getBookingById, AxiosError>((bookingId: string) => {
  return cryptomedicApi.booking.getBookingById(bookingId);
});

$bookings.on(getBookingListFx.doneData, (_, payload) => payload.data);
$booking.on(getBookingByIdFx.doneData, (_, payload) => payload.data);

export const $bookingListError = createStore<AxiosResponse | null>(null)
  .on(getBookingListFx.failData, (state, error) => {
    return error.response;
  })
  .reset(getBookingListFx);

export const $bookingError = createStore<AxiosResponse | null>(null)
  .on(getBookingListFx.failData, (state, error) => {
    return error.response;
  })
  .reset(getBookingListFx);

export const $bookingListLoading = getBookingListFx.pending;
export const $bookingDetailsLoading = getBookingByIdFx.pending;

export const effects = {
  getBookingListFx,
  getBookingByIdFx,
};

export const $getBookingListStatus = combine({
  loading: $bookingListLoading,
  error: $bookingListError,
  data: $bookings,
});

export const $getBookingByIdStatus = combine({
  loading: $bookingDetailsLoading,
  error: $bookingError,
  data: $booking,
});
