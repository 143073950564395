import { Box, Container, Stack, SwipeableDrawer, Typography } from '@mui/material';
import React, { FC, useMemo, useState } from 'react';
import { Button, DrawerContent, PageTitle, BackButton } from 'shared/ui';
import { ServiceCard } from 'entities/service';
import { useStore } from 'effector-react';
import { Service } from 'shared/api';
import { $getServiceListStatus, $orderService, submitted } from 'pages/order/services/model';
import { routes } from 'shared/routes';
import { currency, duration } from 'shared/lib';

export const ServicesPage: FC = () => {
  const { loading, data } = useStore($getServiceListStatus);
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [selectService, setSelectService] = useState<Service | undefined>();
  const orderService = useStore($orderService);

  const handleServiceClick = (service: Service) => {
    setSelectService(service);
    setIsDrawerOpen(true);
  };

  const handleSubmit = () => {
    submitted(orderService);
  };

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    setIsDrawerOpen(open);
  };

  const handleBackButtonClick = () => {
    routes.home.open();
  };

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        pb: 6,
      }}
    >
      <BackButton onClick={handleBackButtonClick} />
      <Container maxWidth="sm">
        <PageTitle header={'Услуги'} subtitle={'Выберите услугу'} backLink={routes.home} />

        <Stack spacing={2}>
          {loading && Array.from(new Array(3)).map((item, index) => <ServiceCard loading key={index} />)}

          {!loading &&
            data.map((service) => (
              <ServiceCard
                onClick={handleServiceClick}
                selected={service.id === orderService?.id}
                data={service}
                key={service.id}
              />
            ))}
        </Stack>
      </Container>
      {orderService && (
        <>
          <Box
            sx={{
              height: '80px',
            }}
          />
          <Box
            sx={{
              position: 'fixed',
              bottom: 0,
              left: 0,
              width: '100%',
              pb: 3,
            }}
          >
            <Container maxWidth="sm">
              <Button
                size={'large'}
                fullWidth
                variant={'contained'}
                disabled={loading}
                loading={loading}
                onClick={handleSubmit}
              >
                Дальше
              </Button>
            </Container>
          </Box>
        </>
      )}
      <SwipeableDrawer
        anchor={'bottom'}
        open={isDrawerOpen}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        elevation={1}
      >
        {selectService && <Drawer service={selectService} onClose={toggleDrawer(false)} />}
      </SwipeableDrawer>
    </Box>
  );
};

type DrawerProps = {
  service: Service;
  onClose?: (event: React.KeyboardEvent | React.MouseEvent) => void;
};

const Drawer: FC<DrawerProps> = ({ service, onClose }) => {
  const handleSubmit = (service: Service) => {
    submitted(service);
  };

  const { serviceDuration, servicePrice } = useMemo(() => {
    const durationObj = duration.convertMinutesToHoursAndMinutes(service.duration);

    return {
      serviceDuration: durationObj.formatString,
      servicePrice: currency.formatPrice(service.price),
    };
  }, [service]);

  return (
    <DrawerContent
      onClose={onClose}
      action={
        <Button
          onClick={() => {
            handleSubmit(service);
          }}
          fullWidth
          variant={'contained'}
          size={'large'}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <span>Выбрать</span>
            <span>{servicePrice}</span>
          </Box>
        </Button>
      }
    >
      <Typography variant={'h3'}> {service.name}</Typography>
      <Typography variant={'body2'} mt={0.5}>
        Длительность сеанса: {serviceDuration}
      </Typography>
      <Typography variant={'body1'} mt={2}>
        {service.description}
      </Typography>
    </DrawerContent>
  );
};
