import { FC, useEffect } from 'react';

export interface BackButtonProps {
  onClick?: () => void;
}
const WebAppBackButton = typeof window !== 'undefined' ? window?.Telegram?.WebApp?.BackButton : null;
let isButtonShown = false;

export const BackButton: FC<BackButtonProps> = ({
  onClick = () => {
    window.history.back();
  },
}) => {
  useEffect(() => {
    if (WebAppBackButton) {
      WebAppBackButton.show();
      isButtonShown = true;
      return () => {
        isButtonShown = false;
        setTimeout(() => {
          if (!isButtonShown) {
            WebAppBackButton.hide();
          }
        }, 10);
      };
    }
  }, []);

  useEffect(() => {
    if (!onClick) {
      return;
    }

    if (WebAppBackButton) {
      WebAppBackButton.onClick(onClick);
      return () => {
        WebAppBackButton.offClick(onClick);
      };
    }
  }, [onClick]);

  return null;
};
