import { combine, createEffect, createEvent, createStore, sample } from 'effector';
import { Service, cryptomedicApi } from 'shared/api';
import { AxiosError, AxiosResponse } from 'axios';
import { routes } from 'shared/routes';
import { orderModel } from 'entities/order';

const currentRoute = routes.order.services;

export const submitted = createEvent<Service | null>();
export const selectedServiceChanged = createEvent<Service | null>();
const getServiceListFx = createEffect<typeof cryptomedicApi.calendar.getServices, AxiosError>(() => {
  return cryptomedicApi.calendar.getServices();
});

export const serviceListInitialState: Service[] = [];
export const $serviceList = createStore<Service[]>(serviceListInitialState).on(
  getServiceListFx.doneData,
  (_, payload) => payload.data,
);
export const $serviceListError = createStore<AxiosResponse | null>(null)
  .on(getServiceListFx.failData, (state, error) => {
    return error.response;
  })
  .reset(getServiceListFx);

export const $getServiceListStatus = combine({
  loading: getServiceListFx.pending,
  error: $serviceListError,
  data: $serviceList,
});

export const $orderService = createStore<Service | null>(null).on(selectedServiceChanged, (_, data) => data);

sample({
  source: submitted,
  filter: (source): source is Service => !!source,
  target: orderModel.serviceChanged,
});

sample({
  clock: currentRoute.opened,
  target: getServiceListFx,
});

sample({
  clock: currentRoute.opened,
  source: orderModel.$order,
  fn: (order) => {
    return order?.service || null;
  },
  target: selectedServiceChanged,
});

$orderService.watch((state) => {
  console.log(state);
});

sample({
  clock: $serviceList,
  source: orderModel.$order,
  fn: (order, list) => {
    if (!order?.service) {
      return null;
    }
    const find = list.find((service) => {
      return service.id === order?.service?.id;
    });
    return find || null;
  },
  target: selectedServiceChanged,
});
