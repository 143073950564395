import { sample } from 'effector';
import { routes } from 'shared/routes';
import { orderServicesPageModel } from 'pages/order/services';
import { orderCalendarPageModel } from 'pages/order/slots';
import { orderInfoPageModel } from 'pages/order/info';
import { redirect } from 'atomic-router';

sample({
  clock: orderServicesPageModel.submitted,
  filter: (service) => !!service,
  target: redirect({ route: routes.order.slots }),
});

sample({
  clock: orderCalendarPageModel.redirectedPrevStep,
  target: redirect({ route: routes.order.services }),
});

sample({
  clock: orderCalendarPageModel.submitted,
  filter: (slot) => !!slot,
  target: redirect({ route: routes.order.info }),
});

sample({
  clock: orderInfoPageModel.redirectedPrevStep,
  target: redirect({ route: routes.order.slots }),
});

sample({
  clock: orderInfoPageModel.submitted,
  target: redirect({ route: routes.order.payment }),
});
