import React, { FC, useMemo, useState } from 'react';
import {
  Autocomplete,
  Avatar,
  Box,
  Card,
  CardActionArea,
  CardContent,
  Container,
  Grid,
  Skeleton,
  Stack,
  SvgIcon,
  SwipeableDrawer,
  TextField,
  Typography,
} from '@mui/material';
import { Slot } from 'shared/api';
import { intl } from 'shared/lib';
import { DrawerContent, PageTitle, BackButton, Button } from 'shared/ui';
import { useStore } from 'effector-react';
import { timezones } from 'shared/const';
import {
  $slotGroupsStatus,
  $days,
  $currentDay,
  dateChanged,
  $selectedSlot,
  selectedSlotChanged,
  $slots,
  submitted,
  getSlotGroupsFx,
  $isTimeZoneEdit,
  openTimeZoneEdit,
  closeTimeZoneEdit,
} from 'pages/order/slots/model';
import { routes } from 'shared/routes';
import { updateUserFx, useAuth } from 'entities/session';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';

export const SlotsPage: FC = () => {
  const { loading } = useStore($slotGroupsStatus);
  const isDrawerOpen = useStore($isTimeZoneEdit);
  const slotDays = useStore($days);
  const currentDate = useStore($currentDay);
  const slots = useStore($slots);
  const selectedSlot = useStore($selectedSlot);
  const { user } = useAuth();
  const updateUserLoading = useStore(updateUserFx.pending);
  const [timezone, setTimezone] = useState<string>(user?.timezone || '');

  const handleBackButtonClick = () => {
    routes.order.services.open();
  };

  const handleSubmitChangeTimezone = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      await updateUserFx({
        timezone: timezone,
      });
      closeTimeZoneEdit();
      await getSlotGroupsFx();
    } catch (e: any) {
      console.log(e);
    }
  };

  const handleChangeDate = (direction: number) => {
    let position: number;

    if (currentDate) {
      const currentPosition = slotDays.indexOf(currentDate);
      position = Math.min(slotDays.length - 1, Math.max(currentPosition + direction, 0));
    } else {
      position = 0;
    }

    dateChanged(slotDays[position]);
  };

  const changeCurrentSlot = (slot: Slot) => {
    if (selectedSlot !== slot) {
      selectedSlotChanged(slot);
    } else {
      selectedSlotChanged(null);
    }
  };

  const handleSubmit = () => {
    if (selectedSlot) {
      submitted(selectedSlot);
    }
  };

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    if (open) {
      openTimeZoneEdit();
    } else {
      closeTimeZoneEdit();
    }
  };

  const formatCurrentSlotTime = useMemo(() => {
    if (selectedSlot) {
      const date = new Date(selectedSlot.startTimeISO);

      return intl.dateFormat(date, {
        dateStyle: 'medium',
        timeStyle: 'short',
        timeZone: selectedSlot.timezone,
      });
    }
    return null;
  }, [selectedSlot]);

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        pb: 6,
      }}
    >
      <BackButton onClick={handleBackButtonClick} />
      <Container maxWidth="sm">
        <PageTitle header={'Выберите время'} subtitle={'И проверьте часовой пояс'} backLink={routes.order.services} />

        <Stack spacing={3}>
          <CalendarDatePicker
            dates={slotDays}
            currentDate={currentDate}
            loading={loading}
            onChangeDate={handleChangeDate}
          />
          <CalendarTimezone loading={loading} timezone={user?.timezone} onClick={toggleDrawer(true)} />
          <CalendarSlotList
            slots={slots}
            currentSlot={selectedSlot}
            onChangeSlot={changeCurrentSlot}
            loading={loading}
          />
        </Stack>
      </Container>

      {selectedSlot && (
        <>
          <Box
            sx={{
              height: '80px',
            }}
          />
          <Box
            sx={{
              position: 'fixed',
              bottom: 0,
              left: 0,
              width: '100%',
              pb: 3,
            }}
          >
            <Container maxWidth="sm">
              <Button
                sx={{
                  height: '64px',
                  textAlign: 'center',
                }}
                size={'large'}
                loading={loading}
                fullWidth
                variant={'contained'}
                disabled={!selectedSlot || loading}
                onClick={handleSubmit}
              >
                <Stack>
                  Выбрать
                  {formatCurrentSlotTime && (
                    <Typography
                      sx={{
                        opacity: 0.8,
                      }}
                      variant={'body2'}
                    >
                      {formatCurrentSlotTime}
                    </Typography>
                  )}
                </Stack>
              </Button>
            </Container>
          </Box>
        </>
      )}

      <SwipeableDrawer
        anchor={'bottom'}
        open={isDrawerOpen}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        elevation={1}
      >
        <form autoComplete="off" noValidate onSubmit={handleSubmitChangeTimezone}>
          <DrawerContent
            sx={{
              height: '90vh',
            }}
            onClose={toggleDrawer(false)}
            action={
              <Button
                fullWidth
                variant={'contained'}
                size={'large'}
                type={'submit'}
                loading={updateUserLoading}
                disabled={updateUserLoading}
              >
                Сохранить
              </Button>
            }
          >
            <Typography variant={'h3'} mb={3}>
              Изменить часовой пояс
            </Typography>
            <Autocomplete
              fullWidth
              disablePortal
              disableClearable
              blurOnSelect
              value={timezone}
              onChange={(e, newValue) => {
                setTimezone(newValue);
              }}
              onClose={() => false}
              id="combo-box-timezone"
              options={Object.keys(timezones)}
              renderInput={(params) => <TextField {...params} required name="timezone" label="Часовой пояс" />}
              ListboxProps={{ style: { maxHeight: '20vh' } }}
            />
          </DrawerContent>
        </form>
      </SwipeableDrawer>
    </Box>
  );
};

type CalendarDatePickerProps = {
  dates: string[];
  currentDate: string | null;
  onChangeDate: (direction: number) => void;
  loading?: boolean;
};

const CalendarDatePicker: FC<CalendarDatePickerProps> = ({ dates, currentDate, onChangeDate, loading }) => {
  const currentFormatDate = useMemo(() => {
    if (!currentDate) return '';

    const date = new Date(currentDate);

    return intl.dateFormat(date, {
      dateStyle: 'medium',
    });
  }, [currentDate]);

  if (loading || !currentDate) {
    return (
      <Stack direction={'row'} spacing={1}>
        <Skeleton variant="rounded" height={56} width={56} sx={{ flexShrink: 0 }} />
        <Skeleton variant="rounded" height={56} width={'100%'} />
        <Skeleton variant="rounded" height={56} width={56} sx={{ flexShrink: 0 }} />
      </Stack>
    );
  }

  return (
    <Stack direction={'row'} spacing={1}>
      <Button
        sx={{
          p: 2,
          minWidth: '0',
        }}
        disabled={dates.indexOf(currentDate) === 0}
        variant={'contained'}
        size={'large'}
        aria-label="Назад"
        onClick={() => {
          onChangeDate(-1);
        }}
      >
        <ArrowBackIcon />
      </Button>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'primary.main',
          borderRadius: 1,
          flexGrow: 1,
          color: 'primary.contrastText',
        }}
      >
        <Typography align={'center'} variant={'body1'}>
          {currentFormatDate}
        </Typography>
      </Box>
      <Button
        sx={{
          p: 2,
          minWidth: '0',
        }}
        disabled={dates.indexOf(currentDate) === dates.length - 1}
        variant={'contained'}
        size={'large'}
        aria-label="Назад"
        onClick={() => {
          onChangeDate(1);
        }}
      >
        <ArrowForwardIcon />
      </Button>
    </Stack>
  );
};

type CalendarSlotListProps = {
  slots: Slot[];
  currentSlot?: Slot | null;
  onChangeSlot?: (slot: Slot) => void;
  loading?: boolean;
};

const CalendarSlotList: FC<CalendarSlotListProps> = ({ slots, currentSlot, onChangeSlot, loading }) => {
  const formatTime = (slot: Slot) => {
    const date = new Date(slot.startTimeISO);

    return intl.dateFormat(date, {
      timeStyle: 'short',
      timeZone: slot.timezone,
    });
  };

  if (loading) {
    return (
      <Box>
        <Grid container spacing={2}>
          {Array.from(new Array(9)).map((item, index) => (
            <Grid item xs={4} key={index}>
              <Skeleton variant="rounded" height={56} />
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  }

  if (slots.length === 0) {
    return (
      <Card>
        <CardContent
          sx={{
            py: 2,
            px: 3,
            '&:last-child': {
              pb: 2,
            },
          }}
        >
          <Stack alignItems="center" justifyContent="center" spacing={1}>
            <Avatar
              sx={{
                backgroundColor: 'primary.main',
                height: 48,
                width: 48,
                fontSize: '20px',
              }}
            >
              😢
            </Avatar>
            <Typography textAlign={'center'} variant={'subtitle1'}>
              На этот день нет свободного времени для записи
            </Typography>
          </Stack>
        </CardContent>
      </Card>
    );
  }

  return (
    <Box>
      <Grid container spacing={2}>
        {slots.map((slot, index) => (
          <Grid key={slot.startTimeISO} item xs={4}>
            <Button
              variant={currentSlot && slot.startTimeISO === currentSlot.startTimeISO ? 'contained' : 'outlined'}
              fullWidth
              sx={{
                px: 1,
                height: '56px',
              }}
              onClick={() => {
                if (onChangeSlot) {
                  onChangeSlot(slot);
                }
              }}
            >
              <Typography variant={'body2'}>{formatTime(slot)}</Typography>
            </Button>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

type CalendarTimezoneProps = {
  timezone?: string;
  loading?: boolean;
  onClick?: (event: React.KeyboardEvent | React.MouseEvent) => void;
};

const CalendarTimezone: FC<CalendarTimezoneProps> = ({ loading, onClick, timezone }) => {
  if (loading) {
    return <Skeleton variant="rounded" height={72} />;
  }

  return (
    <>
      <Card>
        <CardActionArea onClick={onClick}>
          <CardContent
            sx={{
              py: 2,
              px: 3,
            }}
          >
            <Stack alignItems="center" direction="row" justifyContent="space-between" spacing={3}>
              <Stack>
                <Typography
                  sx={{
                    fontSize: '13px',
                  }}
                >
                  Часовой пояс
                </Typography>
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: '16px',
                  }}
                >
                  {timezone}
                </Typography>
              </Stack>
              <Avatar
                sx={{
                  backgroundColor: 'primary.main',
                  height: 36,
                  width: 36,
                  color: 'primary.contrastText',
                }}
              >
                <SvgIcon sx={{ fontSize: '20px' }}>
                  <SettingsOutlinedIcon />
                </SvgIcon>
              </Avatar>
            </Stack>
          </CardContent>
        </CardActionArea>
      </Card>
    </>
  );
};
