import { combine, createEffect, createEvent, createStore, sample } from 'effector';
import { Service, Slot } from 'shared/api';
import { redirect } from 'atomic-router';
import { routes } from 'shared/routes';
import { useStore } from 'effector-react';

type infoType = {
  comment?: string;
};

type paymentType = {
  token: string;
  network: string;
};

type NetworkOption = {
  label: string;
  value: string;
};

type NetworkOptions = {
  [key: string]: NetworkOption[];
};

export const serviceChanged = createEvent<Service>();
export const slotChanged = createEvent<Slot>();
export const infoChanged = createEvent<infoType>();
export const paymentChanged = createEvent<paymentType>();
export const couponChanged = createEvent<string | null>();

export const submitOrderFx = createEffect(() => {
  console.log('Submit');
});

export const submittedOrder = createEvent();

const initialPaymentMethod: NetworkOptions = {
  USDT: [
    { label: 'BSC', value: 'BSC' },
    { label: 'TRX', value: 'TRX' },
  ],
  USDC: [{ label: 'BSC', value: 'BSC' }],
};
export const paymentMethods = createStore<NetworkOptions>(initialPaymentMethod);
export const paymentTokens = paymentMethods.map((methods) => Object.keys(methods));

export const usePaymentMethods = () => {
  const tokens = useStore(paymentTokens);
  const methods = useStore(paymentMethods);

  return {
    methods,
    tokens,
  };
};

export const $service = createStore<Service | null>(null)
  .on(serviceChanged, (state, service) => service)
  .reset(submitOrderFx.done);
export const $slot = createStore<Slot | null>(null)
  .on(slotChanged, (state, slot) => slot)
  .reset(submitOrderFx.done);
export const $info = createStore<infoType>({})
  .on(infoChanged, (state, info) => info)
  .reset(submitOrderFx.done);
const initialPayment = {
  token: 'USDT',
  network: 'TRX',
};
export const $payment = createStore<paymentType>(initialPayment)
  .on(paymentChanged, (state, payment) => payment)
  .reset(submitOrderFx.done);
export const $coupon = createStore<string | null>(null)
  .on(couponChanged, (state, coupon) => coupon)
  .reset(submitOrderFx.done);

export const $order = combine($service, $slot, $info, $payment, $coupon, (service, slot, info, payment, coupon) => {
  return { service, slot, info, payment, coupon };
});

$order.watch((state) => {
  console.log(state);
});

sample({
  clock: submittedOrder,
  source: $order,
  target: submitOrderFx,
});

sample({
  clock: submitOrderFx.done,
  target: redirect({ route: routes.booking.list }),
});

export const useOrder = () => {
  const service = useStore($service);
  const payment = useStore($payment);
  const slot = useStore($slot);
  const coupon = useStore($coupon);
  const price = service?.price || 0;

  return {
    service,
    payment,
    slot,
    coupon,
    price,
  };
};
