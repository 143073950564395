import React from 'react';
import { RouterProvider } from 'atomic-router-react';
import { createHistoryRouter } from 'atomic-router';
import { routesMap } from 'pages';
import { controls, routes } from 'shared/routes';
import { createBrowserHistory } from 'history';

export const router = createHistoryRouter({
  routes: routesMap,
  controls,
  notFoundRoute: routes.notFound,
});

export const withRouter = (component: () => React.ReactNode) => () =>
  <RouterProvider router={router}> {component()}</RouterProvider>;
