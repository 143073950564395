import { FC, PropsWithChildren, ReactNode, useEffect } from 'react';
import { useAuth } from 'entities/session/model';

type AuthGuardProps = PropsWithChildren<{
  pending?: ReactNode;
  fallback?: ReactNode;
}>;

export const AuthGuard: FC<AuthGuardProps> = ({ children, pending, fallback }) => {
  const { isLoading, isAuthenticated } = useAuth();

  useEffect(() => {}, []);

  if (isLoading) return <>{pending}</>;

  return <>{isAuthenticated ? children : fallback}</>;
};
