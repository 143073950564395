import { routes } from 'shared/routes';
import { Route } from 'atomic-router-react';
import { HomePage } from 'pages/home';
import { SettingsPage } from 'pages/settings';
import { ServicesPage } from 'pages/order/services';
import { SlotsPage } from 'pages/order/slots';
import { InfoPage } from 'pages/order/info';
import { PaymentPage } from 'pages/order/payment';
import { BookingsListPage } from 'pages/booking/list';
import { BookingDetailsPage } from 'pages/booking/detail';
import { ErrorPage } from 'pages/error';

export function Pages() {
  return (
    <>
      <Route route={routes.home} view={HomePage} />
      <Route route={routes.settings} view={SettingsPage} />
      <Route route={routes.booking.list} view={BookingsListPage} />
      <Route route={routes.booking.detail} view={BookingDetailsPage} />
      <Route route={routes.order.services} view={ServicesPage} />
      <Route route={routes.order.slots} view={SlotsPage} />
      <Route route={routes.order.info} view={InfoPage} />
      <Route route={routes.order.payment} view={PaymentPage} />
      <Route route={routes.notFound} view={ErrorPage} />
    </>
  );
}

export const routesMap = [
  { path: '/', route: routes.home },
  { path: '/settings/', route: routes.settingsBack },
  { path: '/settings/', route: routes.settings },
  { path: '/bookings', route: routes.booking.list },
  { path: '/bookings/:id', route: routes.booking.detail },
  { path: '/order', route: routes.order.services },
  { path: '/order/slots/:tab(timezone-edit)?', route: routes.order.slots },
  { path: '/order/info/:tab(user-edit)?', route: routes.order.info },
  { path: '/order/payment', route: routes.order.payment },
];
