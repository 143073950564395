import { createRoute, createRouterControls } from 'atomic-router';

export const routes = {
  home: createRoute(),
  settings: createRoute(),
  settingsBack: createRoute(),
  order: {
    services: createRoute(),
    slots: createRoute<{ tab?: string }>(),
    info: createRoute<{ tab?: string }>(),
    payment: createRoute(),
  },
  booking: {
    list: createRoute(),
    detail: createRoute<{ id: string }>(),
  },
  notFound: createRoute(),
};

export const controls = createRouterControls();
