import { Box, Button, Container, Stack, Typography } from '@mui/material';
import React from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { PageTitle } from 'shared/ui';

export const ErrorPage = () => {
  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 6,
      }}
    >
      <Container maxWidth="sm">
        <PageTitle header={'Error'} />
        <Stack spacing={2}>
          <Typography textAlign={'center'}>Sorry, an unexpected error has occurred.</Typography>
          <Box display={'flex'} justifyContent={'center'}></Box>
        </Stack>
      </Container>
    </Box>
  );
};
