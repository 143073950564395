import { AxiosResponse } from 'axios';
import { $api } from 'shared/api/cryptomedic/base';
import { Service, slotGroups } from 'shared/api/cryptomedic/models';

type getSlotsParams = {
  serviceId?: string;
};

export const getSlots = async (params: getSlotsParams = {}): Promise<AxiosResponse<slotGroups>> => {
  return $api.get('telegram-client-webapp/slots', { params });
};

export const getServices = async (): Promise<AxiosResponse<Service[]>> => {
  return $api.get('telegram-client-webapp/services');
};
