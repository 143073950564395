import { declOfNum } from 'shared/lib/string';

export const convertMinutesToHoursAndMinutes = (minutes: number) => {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  const hoursLabel = declOfNum(hours, ['час', 'часа', 'часов']);
  const minutesLabel = declOfNum(remainingMinutes, ['минута', 'минуты', 'минут']);
  const formatString = `${hours > 0 ? `${hours} ${hoursLabel}` : ''} ${
    remainingMinutes > 0 ? `${remainingMinutes} ${minutesLabel}` : ''
  }`.trim();

  return {
    hours,
    minutes: remainingMinutes,
    hoursLabel,
    minutesLabel,
    formatString,
  };
};
