import React, { FC } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { RouteInstance } from 'atomic-router';

type PageTitleProps = {
  header?: string;
  subtitle?: string;
  backLink?: RouteInstance<any>;
};

// export const PageTitle: FC<PageTitleProps> = ({ header, subtitle, backLink }) => {
//   return (
//     <Box
//       mb={3}
//       sx={{
//         position: 'relative',
//         padding: '0 40px',
//       }}
//     >
//       {backLink && (
//         <IconButton
//           sx={{
//             position: 'absolute',
//             top: '-2px',
//             left: '0',
//           }}
//           aria-label="Назад"
//           component={Link}
//           to={backLink}
//         >
//           <ArrowBackIcon />
//         </IconButton>
//       )}
//       <Stack spacing={1}>
//         {header && (
//           <Typography textAlign="center" variant="h1">
//             {header}
//           </Typography>
//         )}
//         {subtitle && (
//           <Typography
//             textAlign="center"
//             variant="body2"
//             sx={{
//               lineHeight: '1.3',
//             }}
//           >
//             {subtitle}
//           </Typography>
//         )}
//       </Stack>
//     </Box>
//   );
// };

export const PageTitle: FC<PageTitleProps> = ({ header, subtitle, backLink }) => {
  return (
    <Box
      sx={{
        pt: 3,
        pb: 3,
      }}
    >
      <Stack spacing={0.5}>
        {header && (
          <Typography component={'h1'} textAlign={'center'} variant="h3">
            {header}
          </Typography>
        )}
        {subtitle && (
          <Typography
            textAlign={'center'}
            variant="body2"
            sx={{
              color: 'text.secondary',
              lineHeight: '1.3',
            }}
          >
            {subtitle}
          </Typography>
        )}
      </Stack>
    </Box>
  );
};
