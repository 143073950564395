import { combine, createStore, forward } from 'effector';
import { User } from 'shared/api';
import { changedLoading, checkFx, loginFx, logout, updateUserFx } from './events';

export const $user = createStore<User | null>(null);
export const $isLoading = createStore<Boolean>(true);
export const $isAuthenticated = $user.map((user) => !!user);

$user
  .on(loginFx.doneData, (_, payload) => payload.data)
  .on(checkFx.doneData, (_, payload) => payload.data)
  .on(updateUserFx.doneData, (_, payload) => payload.data)
  .reset(logout);

$isLoading.on(changedLoading, (_, state) => state);

forward({
  from: loginFx.pending,
  to: $isLoading,
});

export const $auth = combine({
  user: $user,
  isAuthenticated: $isAuthenticated,
  isLoading: $isLoading,
});
