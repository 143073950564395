import axios, { AxiosError } from 'axios';
import { API_URL } from 'shared/config';
import { refreshToken } from 'shared/api/cryptomedic/auth';

export const $api = axios.create({
  withCredentials: true,
  baseURL: `${API_URL}/v1/`,
});

$api.interceptors.request.use((config) => {
  config.headers.Accept = 'application/json';
  config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
  return config;
});

$api.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;

    if (err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;

        try {
          const rs = await refreshToken();

          return $api(originalConfig);
        } catch (_error: any) {
          if (_error.response && _error.response.data) {
            return Promise.reject(_error.response.data);
          }

          return Promise.reject(_error);
        }
      }

      if (err.response.status === 403 && err.response.data) {
        return Promise.reject(err.response.data);
      }
    }

    return Promise.reject(err);
  },
);
