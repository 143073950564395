import {
  Alert,
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Link,
  Snackbar,
  Stack,
  TextField,
} from '@mui/material';
import React, { useCallback, useState } from 'react';
import { Link as RouterLink } from 'atomic-router-react';
import { timezones } from 'shared/const';
import { PageTitle, BackButton } from 'shared/ui';
import { routes } from 'shared/routes';
import { updateUserFx, useAuth } from 'entities/session';

type valuesType = {
  firstName: string;
  lastName: string;
  email: string;
  birthday: string;
  sex: string;
  timezone: string;
};

export const SettingsPage = () => {
  const { user } = useAuth();

  const [values, setValues] = useState<valuesType>({
    firstName: user?.firstName ?? '',
    lastName: user?.lastName ?? '',
    email: user?.email ?? '',
    birthday: user?.birthday ?? '',
    sex: user?.sex ?? '',
    timezone: user?.timezone ?? '',
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState<boolean>(false);

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setValues((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  }, []);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      await updateUserFx(values);
      setOpenSuccessSnackbar(true);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSuccessSnackbar(false);
  };

  const handleBackButtonClick = () => {
    routes.home.open();
  };

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        pb: 6,
      }}
    >
      <BackButton onClick={handleBackButtonClick} />
      <Container maxWidth="sm">
        <PageTitle header={'Настройки'} subtitle={'Введите данные'} backLink={routes.home} />
        <form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            <Box>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Autocomplete
                    fullWidth
                    value={values.timezone}
                    onChange={(e, newValue) => {
                      setValues((prevState) => ({
                        ...prevState,
                        timezone: newValue,
                      }));
                    }}
                    disablePortal
                    disableClearable
                    blurOnSelect
                    onClose={() => false}
                    id="combo-box-timezone"
                    options={Object.keys(timezones)}
                    renderInput={(params) => <TextField {...params} required name="timezone" label="Часовой пояс" />}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField fullWidth label="Имя" name="firstName" onChange={handleChange} value={values.firstName} />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Фамилия"
                    name="lastName"
                    onChange={handleChange}
                    value={values.lastName}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField fullWidth label="Email" name="email" onChange={handleChange} value={values.email} />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Дата рождения"
                    name="birthday"
                    onChange={handleChange}
                    type="date"
                    value={values.birthday}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Пол"
                    name="sex"
                    onChange={handleChange}
                    select
                    SelectProps={{ native: true }}
                    value={values.sex}
                    InputLabelProps={{ shrink: true }}
                  >
                    <option value="">Не выбрано</option>
                    <option value="male">Мужской</option>
                    <option value="female">Женский</option>
                    <option value="other">Другой</option>
                  </TextField>
                </Grid>
              </Grid>
            </Box>
            <Box
              sx={{
                position: 'relative',
              }}
            >
              <Button
                sx={{
                  height: '56px',
                }}
                type={'submit'}
                variant="contained"
                fullWidth
                size={'large'}
                disabled={isLoading}
              >
                Сохранить
              </Button>
              {isLoading && (
                <CircularProgress
                  size={24}
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-12px',
                  }}
                />
              )}
            </Box>
          </Stack>
        </form>

        <Snackbar open={openSuccessSnackbar} onClose={handleCloseSnackbar} autoHideDuration={2000}>
          <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
            Настройки успешно сохранены{' '}
            <Link component={RouterLink} to={routes.home}>
              Записаться на прием
            </Link>
          </Alert>
        </Snackbar>
      </Container>
    </Box>
  );
};
