import { createPalette } from './create-palette';
import { createComponents } from './create-components';
import { createShadows } from './create-shadows';
import { createTypography } from './create-typography';
import { PaletteMode } from '@mui/material';

export const generateTheme = (mode: PaletteMode) => {
  const palette: any = createPalette(mode);
  const components = createComponents({ palette, mode });
  const shadows = createShadows();
  const typography = createTypography();

  return {
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1440,
      },
    },
    palette,
    components,
    typography,
    shape: {
      borderRadius: 8,
    },
    shadows,
  };
};
