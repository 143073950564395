import React, { useEffect } from 'react';
import { createTheme, CssBaseline, PaletteMode, ThemeProvider } from '@mui/material';
import { useTelegram } from 'shared/lib/hooks';
import { generateTheme } from 'shared/config';

export const withTheme = (component: () => React.ReactNode) => () => {
  const [mode, setMode] = React.useState<PaletteMode>('dark');
  const { tg } = useTelegram();

  useEffect(() => {
    if (tg.colorScheme) {
      setMode(tg.colorScheme);
    }
  }, [tg]);

  const theme = React.useMemo(() => createTheme(generateTheme(mode)), [mode]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {component()}
    </ThemeProvider>
  );
};
