import { Box, Button, Container, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { useStore } from 'effector-react';
import { bookingModel, BookingStatus } from 'entities/booking';
import { PageTitle } from 'shared/ui';
import { intl } from 'shared/lib';
import { routes } from 'shared/routes';

export const BookingDetailsPage = () => {
  let bookingId = '1';

  const { data: booking } = useStore(bookingModel.$getBookingByIdStatus);

  let [startTime, setStartTime] = useState<string>('');
  let [startDate, setStartDate] = useState<string>('');

  useEffect(() => {
    if (!bookingId) return;
    bookingModel.effects.getBookingByIdFx(bookingId);
  }, [bookingId]);

  useEffect(() => {
    if (booking?.startDateTime) {
      const date = new Date(booking.startDateTime);

      setStartTime(
        intl.dateFormat(date, {
          timeStyle: 'short',
          timeZone: booking.timezone,
        }),
      );

      setStartDate(
        intl.dateFormat(date, {
          dateStyle: 'medium',
          timeZone: booking.timezone,
        }),
      );
    } else {
      setStartTime('');
      setStartDate('');
    }
  }, [booking]);

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 6,
      }}
    >
      <Container maxWidth="sm">
        {booking && (
          <>
            <PageTitle header={booking?.bookingCode} subtitle={startDate} backLink={routes.booking.list} />

            <Stack spacing={0.5} mb={2}>
              <BookingStatus status={booking.status} />
              <Stack spacing={1} direction={'row'}>
                <Typography>Дата:</Typography>
                <Typography>{startDate}</Typography>
              </Stack>
              <Stack spacing={1} direction={'row'}>
                <Typography>Время:</Typography>
                <Typography>{startTime}</Typography>
              </Stack>
            </Stack>
            <Stack spacing={1}>
              <Button
                sx={{
                  height: '52px',
                }}
                variant={'contained'}
              >
                Заключение
              </Button>
            </Stack>
          </>
        )}
      </Container>
    </Box>
  );
};
