import React, { FC, PropsWithChildren, useMemo } from 'react';
import { Box, Button, Card, CardContent, Skeleton, Stack, Typography } from '@mui/material';
import { Link } from 'atomic-router-react';
import { Booking } from 'shared/api';
import { intl } from 'shared/lib';
import { BookingStatus } from 'entities/booking/ui/booking-status';

type BookingCardProps = PropsWithChildren<{
  data?: Booking;
  loading?: boolean;
}>;

export const BookingCard: FC<BookingCardProps> = ({ data, children, ...cardProps }) => {
  const { startTime, startDate } = useMemo(() => {
    if (!data)
      return {
        startTime: '',
        startDate: '',
      };

    const date = new Date(data.startDateTime);

    const startTime = intl.dateFormat(date, {
      timeStyle: 'short',
      timeZone: data.timezone,
    });

    const startDate = intl.dateFormat(date, {
      dateStyle: 'medium',
      timeZone: data.timezone,
    });

    return {
      startTime,
      startDate,
    };
  }, [data]);

  if (cardProps.loading) {
    return (
      <Card>
        <CardContent
          sx={{
            padding: '20px',
            paddingTop: 3,
          }}
        >
          <Stack spacing={1}>
            <Skeleton variant="text" sx={{ fontSize: '1.3rem' }} />
            <Skeleton variant="text" width={'50%'} sx={{ fontSize: '1rem' }} />
            <Skeleton variant="text" width={'50%'} sx={{ fontSize: '1rem' }} />
            <Skeleton variant="text" width={'50%'} sx={{ fontSize: '1rem' }} />
            <Skeleton variant="rounded" height={56} />
            <Skeleton variant="rounded" height={56} />
          </Stack>
        </CardContent>
      </Card>
    );
  }

  if (!data) {
    return null;
  }

  return (
    <Card>
      <CardContent
        sx={{
          padding: 2.5,
          paddingTop: 3,
          '&:last-child': {
            pb: 3,
          },
        }}
      >
        <Typography variant="h6" mb={2}>
          {data.bookingCode}
        </Typography>
        <Box mb={1}>
          <BookingStatus status={data.status} />
        </Box>

        <Stack spacing={0.5} mb={2}>
          <Stack spacing={1} direction={'row'}>
            <Typography>Дата:</Typography>
            <Typography>{startDate}</Typography>
          </Stack>
          <Stack spacing={1} direction={'row'}>
            <Typography>Время:</Typography>
            <Typography>{startTime}</Typography>
          </Stack>
        </Stack>
        <Stack spacing={1.5}>
          <Button
            sx={{
              height: 52,
            }}
            variant={'contained'}
          >
            Заключение
          </Button>
          <Button
            sx={{
              height: 52,
            }}
            variant={'outlined'}
            component={Link}
            to={`/bookings/${data.id}`}
          >
            Подробнее
          </Button>
        </Stack>
      </CardContent>
    </Card>
  );
};
