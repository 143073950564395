import React, { FC, PropsWithChildren } from 'react';
import { Box, Container, IconButton, SxProps } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

type DrawerContentProps = PropsWithChildren<{
  action?: React.ReactNode;
  onClose?: (event: React.KeyboardEvent | React.MouseEvent) => void;
  sx?: SxProps;
}>;

export const DrawerContent: FC<DrawerContentProps> = ({ children, action, onClose, sx }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        maxHeight: '90vh',
        minHeight: '100px',
        height: '100%',
        overflow: 'hidden',
        ...sx,
      }}
    >
      <Box
        sx={{
          flexShrink: 1,
          flexGrow: 1,
          height: '100%',
          overflow: 'auto',
        }}
      >
        <Container maxWidth="sm">
          <Box
            sx={{
              position: 'relative',
              pb: '20px',
              pt: 4,
            }}
          >
            {children}
          </Box>
        </Container>
      </Box>

      {!!action && (
        <Box
          sx={{
            flexShrink: 0,
            flexGrow: 0,
            py: 2,
            borderTopWidth: '1px',
            borderTopStyle: 'solid',
            borderColor: 'divider',
          }}
        >
          <Container maxWidth="sm">{action}</Container>
        </Box>
      )}

      {!!onClose && (
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            top: '24px',
            right: '16px',
            backgroundColor: 'background.paper',
            border: '1px solid',
            borderColor: 'divider',
          }}
        >
          <CloseRoundedIcon />
        </IconButton>
        // <Box
        //   sx={{
        //     position: 'absolute',
        //     top: '28px',
        //     left: '0px',
        //     width: '100%',
        //   }}
        // >
        //   <Container maxWidth="sm">
        //     <Box
        //       sx={{
        //         display: 'flex',
        //         justifyContent: 'flex-end',
        //       }}
        //     >
        //
        //     </Box>
        //   </Container>
        // </Box>
      )}
    </Box>
  );
};
